svg#freepik_stories-astronaut-suit:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-astronaut-suit.animated #freepik--Background--inject-3 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
  animation-delay: 0s;
}
svg#freepik_stories-astronaut-suit.animated #freepik--Shadow--inject-3 {
  animation: 0.9s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
svg#freepik_stories-astronaut-suit.animated #freepik--Stars--inject-3 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 1.6s;
  opacity: 0;
}

svg#freepik_stories-astronaut-suit.animated #freepik--Stars--inject-3 {
  animation: 6s Infinite linear floating2;
  animation-delay: 1.7s;
}

svg#freepik_stories-astronaut-suit.animated #freepik--Character--inject-3 {
  animation: 0.7s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
  animation-delay: 0s;
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes floating2 {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  10% {
    opacity: 1;
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
}


.animator-hidden {
  display: none;
}
.astronaut-bg{
    @apply fill-orange-200 dark:fill-slate-900 !important
}
.astronaut-shadow{
    @apply fill-orange-300 dark:fill-slate-800 !important
}
.astronaut-stars *{
    @apply fill-orange-400 !important
}

#elmhm7z3jrj79 path{
  @apply fill-slate-400 !important
}