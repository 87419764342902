svg#freepik_stories-astronaut-helmet:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-astronaut-helmet.animated #freepik--Background--inject-3 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) zoomIn;
  animation-delay: 0s;
}
svg#freepik_stories-astronaut-helmet.animated #freepik--Stars--inject-3 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 1.5s;
  opacity: 0;
}
svg#freepik_stories-astronaut-helmet.animated
  #freepik--astronaut-helmet--inject-3 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
.animator-hidden {
  display: none;
}
#elf91subzr2mw *, #ellliaqpd0wtd, #freepik--Reflection--inject-3{
  @apply hidden !important
}
#eltao3pwrzb5n{
  @apply fill-slate-300 !important
}
#eltao3pwrzb5n{
  @apply fill-slate-400 !important
}
#ellsotmyalgz{
  @apply fill-slate-400 !important
}
#freepik--background--inject-3{
  @apply fill-orange-300 dark:fill-slate-900 !important
}