svg#freepik_stories-to-the-stars:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-to-the-stars.animated #freepik--Space--inject-1--inject-20 {
  animation: 3s Infinite linear wind;
  animation-delay: 0s;
}
svg#freepik_stories-to-the-stars.animated #freepik--Smoke--inject-1--inject-20 {
  animation: 6s Infinite linear wind;
  animation-delay: 0s;
}
svg#freepik_stories-to-the-stars.animated
  #freepik--Rocket--inject-1--inject-20 {
  animation: 6s Infinite linear floating;
  animation-delay: 0s;
}
svg#freepik_stories-to-the-stars.animated #freepik--Stars--inject-1--inject-20 {
  animation: 3s Infinite linear floating;
  animation-delay: 4s;
}
svg#freepik_stories-to-the-stars.animated
  #freepik--Meteorites--inject-1--inject-20  #elwasdevv7q4k {
  animation: 1s Infinite linear floating-disolve1;
  animation-delay: 0.5s;
  opacity: 0;
}

svg#freepik_stories-to-the-stars.animated
  #freepik--Meteorites--inject-1--inject-20  #el7hq4vzc49l2 {
  animation: 1s Infinite linear floating-disolve1;
  animation-delay: 0.5s;
  opacity: 0;
}

svg#freepik_stories-to-the-stars.animated
  #freepik--Meteorites--inject-1--inject-20  #el4p6crdpk9a8 {
  animation: 1s Infinite linear floating-disolve2;
  animation-delay: 1.75s;
  opacity: 0;
}

svg#freepik_stories-to-the-stars.animated
  #freepik--Meteorites--inject-1--inject-20  #elqavvijv4joq {
  animation: 1s Infinite linear floating-disolve2;
  animation-delay: 1.75s;
  opacity: 0;
}

svg#freepik_stories-to-the-stars.animated
  #freepik--Meteorites--inject-1--inject-20  #elhdr5c34p7sd {
  animation: 1s Infinite linear floating-disolve3;
  animation-delay: 1.5s;
  opacity: 0;
}

svg#freepik_stories-to-the-stars.animated
  #freepik--Meteorites--inject-1--inject-20  #elr29v28qxpza {
  animation: 1s Infinite linear floating-disolve3;
  animation-delay: 1.5s;
  opacity: 0;

}

 
@keyframes wind {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(1deg);
  }
  75% {
    transform: rotate(-1deg);
  }
}
@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes floating-disolve1 {

    20%, 100%{
        opacity: 0;
        transform: translateY(100px);
    }
    0% {
      opacity: 0;
      transform: translateY(-100px);
      
    }
    7%, 8% {
      opacity: 1;

    }
  }

  @keyframes floating-disolve2 {
    20%, 100%{
        opacity: 0;
        transform: translateY(100px);
    }
    0% {
      opacity: 0;
      transform: translateY(-100px);
      
    }
    7%, 8% {
      opacity: 1;

    }
  }

  @keyframes floating-disolve3 {
    20%, 100%{
        opacity: 0;
        transform: translateY(80px);
    }
    0% {
      opacity: 0;
      transform: translateY(-80px);
      
    }
    7%, 8% {
      opacity: 1;

    }
  }


#elmjabzb9jy7e{
    @apply fill-blue-300 dark:fill-slate-900 !important
}
#elw0bfrpfg2vm{
    @apply fill-blue-300 dark:fill-slate-900 !important
}
#elb87bufgdg{
    @apply fill-slate-100 dark:fill-slate-800 !important
}
.light-smoke{
    @apply fill-slate-400 dark:fill-slate-700 !important
}
#elqmrhiul6hds , #ele1r3u37j3ye, #elty812sdqfn, #el4im8mcseate, #el5etd4ramq4s, #elj5ahgu8d1hk, #el52u8dbqle9v, #elxg9sdbbiu{
    @apply fill-orange-300 dark:fill-orange-400 !important
}